<template>
    <transition name="fade">
        <div id="sn-preview" class="image-preview" :class="{active: $store.getters['imagePreview/getState'] }" @click="hidePreview" :key="$store.getters['imagePreview/getImage']">
            <div class="sn-preview-wrapper">
                <svg class="sn-preview-icon"
                     width="25"
                     height="24"
                     viewBox="0 0 25 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                     @click="$store.dispatch('imagePreview/hidePreview')">
                    <path d="M0 21.4238L2.56494 23.9941L12.017 14.5312L21.468 23.9941L24.068 21.4238L14.51 12L23.968 2.51758L21.4399 0L12.02 9.41797L2.51404 0L0.00195312 2.51758L9.44299 12L0 21.4238Z" fill="#2E2E2E"/>
                </svg>
                <img :src="$store.getters['imagePreview/getImage']">
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'image-preview',
        methods: {
            hidePreview(event) {
                let preview = document.getElementById('sn-preview')
                if (event.target == preview) {
                    this.$store.dispatch('imagePreview/hidePreview')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '~assets/stylesheets/animations/fade';

    .sn-preview-icon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        color: #000;
        cursor: pointer;
    }

    .sn-preview-wrapper {
        position: relative;
    }

    .image-preview{
        cursor: pointer;
        display: none;
        position: fixed;
        z-index: 99999;
        padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.9);
        top: 0;
        left: 0;

        &.active{
            display: flex;
            justify-content: center;
        }

        img{
            cursor: default;
            display: block;
            max-width: 100%;
            max-height: 500px;
        }
    }

</style>