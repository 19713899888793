import * as api_config from '../config/api_config'

export default {
    addToOrder(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/orders/add_to_cart`, payload, {
            headers: api_config.HEADERS
        })
    },
    updateQuantity(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/orders/update_quantity`, payload, {
            headers: api_config.HEADERS
        })
    },
    getOrder(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/orders/cart`, payload, {
            headers: api_config.HEADERS
        })
    },
    getUnscopedOrder(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/orders/get_order`, payload, {
            headers: api_config.HEADERS
        })
    },
    applyCouponCode(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/orders/apply_coupon_code`, payload, {
            headers: api_config.HEADERS
        })
    },
    clearCouponCode(axios, params) {
        return axios.delete(`${api_config.ADDRESS}/orders/clear_coupon_code`, {
            headers: api_config.HEADERS,
            params
        })
    },
    applyCouponCodeByEmail(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/orders/apply_coupon_code_by_email`, payload, {
            headers: api_config.HEADERS
        })
    },
    applyGiftCard(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/orders/apply_gift_card`, payload, {
            headers: api_config.HEADERS
        })
    },
    clearCouponCodes(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/orders/clear_coupon_codes`, payload, {
            headers: api_config.HEADERS
        })
    },
    applyOrderShippingAddress(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/checkout/set_shipping_address`, payload, {
            headers: api_config.HEADERS,
            proxy: api_config.PROXY
        })
    },
    updateBillingAddress(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/checkout/set_billing_address`, payload, {
            headers: api_config.HEADERS
        })
    },
    updateUnscopedBillingAddress(axios, orderId, payload) {
        return axios.post(`${api_config.ADDRESS}/orders/${orderId}/set_billing_address`, payload, {
            headers: api_config.HEADERS,
            proxy: api_config.PROXY
        })
    },
    applyServiceLevelOptions(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/checkout/set_service_level_options`, payload, {
            headers: api_config.HEADERS
        })
    },
    orderAndPay(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/checkout/order_and_pay`, payload, {
            headers: api_config.HEADERS
        })
    },
    breadOrderProductPage(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/checkout/bread_order_product_page`, payload, {
            headers: api_config.HEADERS
        })
    },
    getOrderNumber(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/orders/get_order_number`, payload, {
            headers: api_config.HEADERS
        })
    },
    setPaypalExpressCheckout(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/checkout/set_paypal_express_checkout`, payload, {
            headers: api_config.HEADERS
        })
    },
    doPaypalExpressCheckout(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/checkout/do_paypal_express_checkout`, payload, {
            headers: api_config.HEADERS,
            proxy: api_config.PROXY
        })
    },
    getShippingAddress(axios, id) {
        return axios.get(`${api_config.ADDRESS}/orders/${id}/shipping_address`, {
            headers: api_config.HEADERS
        })
    },
    getUserOrders(axios, payload) {
        return axios.get(`${api_config.ADDRESS}/orders/get_user_orders`, {
            params: payload,
            headers: api_config.HEADERS
        })
    },
    getUserLastProcessingOrder(axios) {
        return axios.get(`${api_config.ADDRESS}/orders/user_last_processing_order`, {
            headers: api_config.HEADERS
        })
    },
    changeOrderGiftCardUsage(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/orders/change_order_gift_card_usage`, payload, {
            headers: api_config.HEADERS
        })
    },
    appleValidateMerchant(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/checkout/apple_validate_merchant`, payload, {
            headers: api_config.HEADERS
        })
    },
    appleProcessPayment(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/checkout/apple_process_payment`, payload, {
            headers: api_config.HEADERS
        })
    },
    appleCalculateShippingPrice(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/checkout/apple_calculate_shipping_price`, payload, {
            headers: api_config.HEADERS
        })
    },
    appleInitialShipping(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/checkout/apple_initial_shipping`, payload, {
            headers: api_config.HEADERS
        })
    },
    generateDiscount(axios, orderId, payload) {
        return axios.post(`${api_config.ADDRESS}/orders/${orderId}/generate_discount`, payload, {
            headers: api_config.HEADERS
        })
    }
  }
