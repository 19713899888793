import { entries, compact } from 'lodash'

export default {
  data () {
    return {
      defaultFbPixelPayload: {
        content_type: 'product',
        content_name: '',
        content_category: '',
        content_ids: [],
        currency: 'USD',
        value: 0
      }
    }
  },
  methods: {
    fbPixelTrackViewContent (payload) {
      this.fbPixelTrack('ViewContent', this.getFbPixelPayload(payload))
    },
    fbPixelTrackPurchase (payload) {
      this.fbPixelTrack('Purchase', this.getFbPixelPayload(payload))
    },
    fbPixelTrackInitiateCheckout (payload) {
      this.fbPixelTrack('InitiateCheckout', this.getFbPixelPayload(payload))
    },
    fbPixelTrackAddPaymentInfo (payload) {
      this.fbPixelTrack('AddPaymentInfo', this.getFbPixelPayload(payload))
    },
    fbPixelTrackAddToCart (payload) {
      this.fbPixelTrack('AddToCart', this.getFbPixelPayload(payload))
    },
    fbPixelTrack (event, payload) {
      if (!this.$config.PRODUCTION) return

      fbq('track', event, payload)
    },
    getFbPixelPayload (payload) {
      return { ...this.defaultFbPixelPayload, ...payload }
    },
    fbPixelTrackLead (category, name) {
      this.fbPixelTrack('Lead', {
        content_category: category,
        content_name: name,
        currency: 'USD',
        value: 0
      })
    },
    fbPixelTrack40kRegistrationComplete () {
      this.fbPixelTrack('CompleteRegistration', {
        content_name: '40k-giveaway',
        currency: 'USD',
        status: 'complete',
        value: 0
      })
    },
    fbPixelTrack40kStep2ViewContent (inMarketRoomOption, furniturePreferenceOptions) {
      const optionsVariantIdMapping = {
        'living_room-sofas_and_sectionals': '58ba052a-a7fc-4f46-9769-08559593329a',
        'living_room-chairs_and_chaises': '54d11d4c-a13b-40ed-b1ab-dd1722a3546e',
        'living_room-coffee_tables': '88c9a3c5-24df-45b9-a5c1-14ad9030867a',
        'living_room-end_tables': '4944c6f0-a6a7-40b8-82d8-bde58eb5b067',
        'living_room-media_consoles': '5b11cf63-0c3e-43f6-898d-4c82a4439e05',
        'living_room-bookcases_and_cabinets': '770e5cf6-c259-46a1-918e-b14433ad776b',
        'living_room-ottomans_and_poufs': '1b2f5da2-eb17-46b8-ba15-93a88dd25181',
        'living_room-lighting': '9abc72dc-7c08-4e1a-b649-a8dd09fc4107',
        'living_room-rugs_and_runners': 'ce34da73-c23b-4299-974e-8f426d32e6a7',
        'living_room-accents_and_decor': 'e4cc9da1-cabe-4370-b4b4-653dd218fcdf',
        'kitchen_dining-dining_tables': 'e212559a-9879-4f0a-87ba-effce65c1fe9',
        'kitchen_dining-dining_chairs': '257902a7-f2cc-436a-bf5b-94713e3d4236',
        'kitchen_dining-bar_and_counter_stools': '328f0335-8a28-4645-ba57-ed78ca0a2605',
        'kitchen_dining-cabinets_and_storage': '746962e1-9c12-41bc-b959-1bc3e037b0e5',
        'kitchen_dining-kitchen_island': '3c86b9f3-9bd1-4bcf-9417-7865cca9850f',
        'kitchen_dining-kitchen_accessories': 'ee7d717f-609e-4c2f-b5fd-d7b323f2068d',
        'kitchen_dining-tabletop': '00b2bc73-153b-41f4-ae93-0d4ffa1b5474',
        'bedroom-beds_and_headboards': 'a5624bc5-7751-45c9-aca7-6a4602908db5',
        'bedroom-dressers': '4e00cabf-87f9-4270-842b-c2f5de5a72af',
        'bedroom-nightstands': 'a6f6d114-40f4-414e-aff4-0add1a0dba50',
        'bedroom-seating': '1fdb2e97-0079-4bf8-a9fa-3fcec635e62f',
        'bedroom-bookcases_and_cabinets': 'c957056b-7462-4383-a657-ede607f73bf6',
        'bedroom-trunks': '0e852f95-93ff-4139-9484-9a506a9c9ac1',
        'bedroom-bedding': '5c757608-7aa7-4953-921e-181b62c6daec',
        'office-desks': 'f1365db2-909d-42c4-8d51-cca3141117e7',
        'office-desk_chairs': '5ca3dcf3-d1f6-468f-8226-e2191c735248',
        'office-file_storage': null,
        'office-bookcases_and_shelves': null,
        'office-accent_chairs': null,
        'entryway-console_tables': 'acdbf924-dc28-48d1-b6ea-76f19d0344ea',
        'entryway-benches': 'b9dab086-7fa4-4edd-9f6e-a84928ee85a2',
        'entertainment_area-game_tables': '74b3c904-c1d8-42d0-b179-058f4f727fc1',
        'entertainment_area-coffee_tables': '378382ab-8e74-4b6f-a067-d0c82b4a1f2e',
        'entertainment_area-end_tables': '4944c6f0-a6a7-40b8-82d8-bde58eb5b067',
        'entertainment_area-media_and_storage': '5579d00b-6b01-479b-970b-321ad93f6068',
        'entertainment_area-bar_carts_and_cabinets': 'f12508df-5ef1-4f52-896c-8f9910dfac9d'
      }
      const variantIdsEntries = entries(optionsVariantIdMapping).filter(([key, _value]) => {
        const combinedKeys = (furniturePreferenceOptions || []).map(e => `${inMarketRoomOption}-${e}`)
        return combinedKeys.includes(key)
      })
      const variantIds = compact(variantIdsEntries.map(e => e[1]))
      if (variantIds.length === 0) return

      this.fbPixelTrackViewContent({
        content_category: 'contest',
        content_name: '40k-giveaway',
        content_ids: variantIds
      })
    },
    fbPixelTrack40kStep5ViewContent (outdoorSpace) {
      if (!outdoorSpace) return

      this.fbPixelTrackViewContent({
        content_category: 'contest',
        content_name: '40k-giveaway',
        content_ids: ['c22dc400-479d-4112-b17c-d2222cd9376b']
      })
    }
  }
}

