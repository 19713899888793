import Auth from '@/api/Auth'
import User from '@/api/User'
import CordialMixin from '@/mixins/cordial'
import PinterestConversionEvents from '@/mixins/pinterestConversionEvents'
import {LOCAL_STORE_DESIGNER_DISCOUNT} from './user'
import { isNil } from 'lodash'

const LOCAL_STORE_AUTH_TOKEN = 'sanAT'
const LOCAL_STORE_AUTH_ROLE = 'sanAR'
const LOCAL_STORE_EMAIL = 'sanEM'
const LOCAL_STORE_USER_ID = 'sanUID'
const LOCAL_STORE_SIGNED_IN_STAGING = 'sanSIS'
const STAGING_PASSWORD = 'staging'

export const state = () => ({
    token: null,
    role: null,
    email: null,
    userId: null,
    signedInStaging: false
})
export const getters = {
    signedUser: state => {
        return !!state.token && state.role !== 'admin'
    },
    signedAdmin: state => {
        return !!state.token && state.role === 'admin'
    },
    signedDesigner: state => {
        return !!state.token && (state.role === 'trade_designer' || state.role === 'room_designer')
    },
    signedIn: state => {
        return !!state.token
    },
    getEmail: state => {
        return state.email
    },
    getUserId: state => {
        return state.userId
    },
    getToken: state => {
        return state.token
    },
    getSignedInStaging: state => {
        return state.signedInStaging
    }
}
export const mutations = {
    setUser(state, payload) {
        state.token = payload.token
        state.role = payload.role
        state.email = payload.email
        state.userId = payload.userId
    },
    clearUser(state) {
        state.token = null
        state.role = null
        state.email = null
        state.userId = null
    },
    setSignedInStaging (state, payload) {
        state.signedInStaging = payload
    }
}
export const actions = {
    initAuth({ commit }, context) {
        let token, role, email, userId, signedInStaging
        token = context.$cookies.get(LOCAL_STORE_AUTH_TOKEN)
        role = context.$cookies.get(LOCAL_STORE_AUTH_ROLE)
        email = context.$cookies.get(LOCAL_STORE_EMAIL)
        userId = context.$cookies.get(LOCAL_STORE_USER_ID)
        signedInStaging = context.$cookies.get(LOCAL_STORE_SIGNED_IN_STAGING)

        commit('setUser', { token, role, email, userId })
        commit('setSignedInStaging', signedInStaging)
    },
    initialUser({state, commit, dispatch, rootGetters}) {
        dispatch('setAxiosHeader', { name: 'Authorization', value: state.token })
        return User.initialCheck(this.$axios, {order_id: rootGetters['order/getOrderId']})
            .then(response => {
                let role = response.data.role
                let email = response.data.email
                let order_id = response.data.order_id
                let order = response.data.order
                let first_order_promotion = response.data.first_order_promotion
                let applied_coupon_code = response.data.applied_coupon_code
                let designer_discount_applied = response.data.designer_discount_applied
                let sms_notifications_enabled = response.data.sms_notifications_enabled
                let favorites = response.data.favorites

                this.$axios.setToken(state.token)
                dispatch('order/setOrderId', order_id, {root: true})
                dispatch('order/setOrder', order, {root: true})

                commit('setUser', {token: state.token, role: role, email: email})

                commit('user/setFirstOrderPromotionAvaiable', first_order_promotion, {root: true})
                commit('user/setAppliedCouponCode', applied_coupon_code, {root: true})
                dispatch('user/setDesignerDiscountApplied', designer_discount_applied, {root: true})
                dispatch('user/setSmsNotificationsEnabled', sms_notifications_enabled, {root: true})
                dispatch('user/setFavorites', favorites, { root: true })

                let cart_data = rootGetters['order/getCartCordial']
                if (process.client) {
                    CordialMixin.methods.identifyCordialUser(email, cart_data)
                }

            })
            .catch(error => {
                dispatch('setAxiosHeader', { name: 'Authorization', value: null })
                dispatch('removeDataItem', LOCAL_STORE_AUTH_TOKEN)
                dispatch('removeDataItem', LOCAL_STORE_AUTH_ROLE)
                dispatch('removeDataItem', LOCAL_STORE_EMAIL)
                dispatch('removeDataItem', LOCAL_STORE_DESIGNER_DISCOUNT)
                dispatch('removeDataItem', LOCAL_STORE_USER_ID)
                commit('clearUser')
                if (!!error.response.data.remove_order) {
                    dispatch('order/clearOrderId', {}, {root: true})
                    dispatch('order/clearOrder', {}, {root: true})
                }
            })
    },
    signUp({commit, dispatch}, credentials) {
        return new Promise((resolve, reject) => {
            Auth.signUp(this.$axios, credentials)
                .then(response => {
                    let auth_token = response.headers.authorization
                    let role = response.data.role
                    let first_order_promotion = response.data.first_order_promotion
                    let email = response.data.email
                    let userId = response.data.user_id

                    dispatch('setAxiosHeader', { name: 'Authorization', value: auth_token })
                    dispatch('setDataItem', { name: LOCAL_STORE_AUTH_TOKEN, value: auth_token })
                    dispatch('setDataItem', { name: LOCAL_STORE_AUTH_ROLE, value: role })
                    dispatch('setDataItem', { name: LOCAL_STORE_EMAIL, value: email })
                    dispatch('setDataItem', { name: LOCAL_STORE_USER_ID, value: userId })

                    commit('user/setFirstOrderPromotionAvaiable', first_order_promotion, {root: true})
                    commit('setUser', {token: auth_token, role: role, email: email})
                    console.log('sign up 1')
                    if (process.client) {
                        CordialMixin.methods.identifyCordialUser(email)
                        PinterestConversionEvents.methods.pinterestSignup()
                    }
                    console.log('sign up 2')

                    resolve(response)
                })
                .catch(error => {
                    dispatch('setAxiosHeader', { name: 'Authorization', value: null })
                    dispatch('removeDataItem', LOCAL_STORE_AUTH_TOKEN)
                    dispatch('removeDataItem', LOCAL_STORE_AUTH_ROLE)
                    dispatch('removeDataItem', LOCAL_STORE_EMAIL)
                    dispatch('removeDataItem', LOCAL_STORE_DESIGNER_DISCOUNT)
                    dispatch('removeDataItem', LOCAL_STORE_USER_ID)

                    commit('clearUser')
                    reject(error)
                })
        })
    },
    stagingSignIn ({ dispatch, commit }, password) {
        if (password !== STAGING_PASSWORD) { return false }

        dispatch('setDataItem', { name: LOCAL_STORE_SIGNED_IN_STAGING, value: true })
        commit('setSignedInStaging', true)

        return true
    },
    signIn({commit, dispatch, rootGetters}, credentials) {
        return new Promise((resolve, reject) => {
            let additional_params = {order_id: rootGetters['order/getOrderId']}
            Auth.signIn(this.$axios, credentials, additional_params)
                .then(response => {
                    let auth_token = response.headers.authorization

                    let role = response.data.role
                    let email = response.data.email
                    let order_id = response.data.order_id
                    let order = response.data.order
                    let first_order_promotion = response.data.first_order_promotion
                    let applied_coupon_code = response.data.applied_coupon_code
                    let designer_discount_applied = response.data.designer_discount_applied
                    let userId = response.data.user_id
                    let sms_notifications_enabled = response.data.sms_notifications_enabled
                    let favorites = response.data.favorites

                    dispatch('setAxiosHeader', { name: 'Authorization', value: auth_token })

                    dispatch('setDataItem', { name: LOCAL_STORE_AUTH_TOKEN, value: auth_token })
                    dispatch('setDataItem', { name: LOCAL_STORE_AUTH_ROLE, value: role })
                    dispatch('setDataItem', { name: LOCAL_STORE_EMAIL, value: email })
                    dispatch('setDataItem', { name: LOCAL_STORE_USER_ID, value: userId })

                    commit('setUser', {token: auth_token, role: role, email: email, userId: userId})

                    commit('user/setFirstOrderPromotionAvaiable', first_order_promotion, {root: true})
                    commit('user/setAppliedCouponCode', applied_coupon_code, {root: true})
                    dispatch('user/setDesignerDiscountApplied', designer_discount_applied, {root: true})
                    dispatch('user/setSmsNotificationsEnabled', sms_notifications_enabled, {root: true})
                    dispatch('user/setFavorites', favorites, { root: true })

                    let cart_data = rootGetters['order/getCartCordial']
                    if (process.client) {
                        CordialMixin.methods.identifyCordialUser(email, cart_data)
                    }

                    if (!!order_id) {
                        dispatch('order/setOrderId', order_id, {root: true})
                        dispatch('order/setOrder', {order: order}, {root: true})
                    }

                    resolve(response)
                })
                .catch(error => {
                    dispatch('setAxiosHeader', { name: 'Authorization', value: null })
                    dispatch('removeDataItem', LOCAL_STORE_AUTH_TOKEN)
                    dispatch('removeDataItem', LOCAL_STORE_AUTH_ROLE)
                    dispatch('removeDataItem', LOCAL_STORE_EMAIL)
                    dispatch('removeDataItem', LOCAL_STORE_DESIGNER_DISCOUNT)
                    dispatch('removeDataItem', LOCAL_STORE_USER_ID)

                    commit('clearUser')
                    reject(error)
                })
        })
    },
    facebookSignIn({commit, dispatch, state}, params) {
        let additional_params = {order_id: state['order/getOrderId']}
        return new Promise((resolve, reject) => {
            Auth.facebookSignIn(this.$axios, Object.assign(params, additional_params))
                .then(response => {
                    let auth_token = response.headers.authorization

                    let role = response.data.role
                    let email = response.data.email
                    let order_id = response.data.order_id
                    let order = response.data.order
                    let first_order_promotion = response.data.first_order_promotion
                    let applied_coupon_code = response.data.applied_coupon_code
                    let designer_discount_applied = response.data.designer_discount_applied
                    let userId = response.data.user_id
                    let sms_notifications_enabled = response.data.sms_notifications_enabled
                    let favorites = response.data.favorites

                    dispatch('setAxiosHeader', { name: 'Authorization', value: auth_token })
                    dispatch('setDataItem', { name: LOCAL_STORE_AUTH_TOKEN, value: auth_token })
                    dispatch('setDataItem', { name: LOCAL_STORE_AUTH_ROLE, value: role })
                    dispatch('setDataItem', { name: LOCAL_STORE_EMAIL, value: email })
                    dispatch('setDataItem', { name: LOCAL_STORE_USER_ID, value: userId })
                    commit('setUser', {token: auth_token, role: role, email: email})

                    commit('user/setFirstOrderPromotionAvaiable', first_order_promotion, {root: true})
                    commit('user/setAppliedCouponCode', applied_coupon_code, {root: true})
                    dispatch('user/setDesignerDiscountApplied', designer_discount_applied, {root: true})
                    dispatch('user/setSmsNotificationsEnabled', sms_notifications_enabled, {root: true})
                    dispatch('user/setFavorites', favorites, { root: true })
                    console.log('sign in 1')

                    if (process.client) {
                        CordialMixin.methods.identifyCordialUser(email)
                    }
                    console.log('sign in 2')

                    if (!!order_id) {
                        dispatch('order/setOrderId', order_id, {root: true})
                        dispatch('order/setOrder', {order: order}, {root: true})
                    }

                    resolve(response)
                })
                .catch(error => {
                    dispatch('setAxiosHeader', { name: 'Authorization', value: null })
                    dispatch('removeDataItem', LOCAL_STORE_AUTH_TOKEN)
                    dispatch('removeDataItem', LOCAL_STORE_AUTH_ROLE)
                    dispatch('removeDataItem', LOCAL_STORE_EMAIL)
                    dispatch('removeDataItem', LOCAL_STORE_DESIGNER_DISCOUNT)
                    dispatch('removeDataItem', LOCAL_STORE_USER_ID)

                    commit('clearUser')
                    reject(error)
                })
        })
    },
    signOut({commit, state, dispatch}) {
        return new Promise((resolve, reject) => {
            Auth.signOut(this.$axios, state.token)
                .then(response => {
                    dispatch('setAxiosHeader', { name: 'Authorization', value: null })
                    dispatch('removeDataItem', LOCAL_STORE_AUTH_TOKEN)
                    dispatch('removeDataItem', LOCAL_STORE_AUTH_ROLE)
                    dispatch('removeDataItem', LOCAL_STORE_EMAIL)
                    dispatch('removeDataItem', LOCAL_STORE_USER_ID)

                    commit('clearUser')
                    dispatch('user/clearUser', {}, {root: true})
                    dispatch('order/clearOrderId', {}, {root: true})
                    dispatch('order/clearOrder', {}, {root: true})
                    if (process.client) {
                        CordialMixin.methods.forgetCordialUser()
                    }
                    resolve(response)
                })
                .catch(error => {
                    dispatch('setAxiosHeader', { name: 'Authorization', value: null })
                    dispatch('removeDataItem', LOCAL_STORE_AUTH_TOKEN)
                    dispatch('removeDataItem', LOCAL_STORE_AUTH_ROLE)
                    dispatch('removeDataItem', LOCAL_STORE_EMAIL)
                    dispatch('removeDataItem', LOCAL_STORE_USER_ID)

                    commit('clearUser')
                    dispatch('user/clearUser', {}, {root: true})
                    dispatch('order/clearOrderId', {}, {root: true})
                    dispatch('order/clearOrder', {}, {root: true})
                    reject(error)
                })
        })
    },
    setDataItem(_, payload) {
        let defaultOpts = { path: '/' }

        if (!payload.opts) {
            payload.opts = { maxAge: 3600 * 24 }
        }
        this.$cookies.set(payload.name, payload.value, { ...defaultOpts, ...payload.opts })
    },
    removeDataItem(_, name) {
        this.$cookies.remove(name)
    },
    setAxiosHeader(_context, payload) {
        if (isNil(payload.value)) {
            delete this.$axios.defaults.headers.common[payload.name]
        } else {
            this.$axios.defaults.headers.common[payload.name] = payload.value
        }
    }
}
