import ApiOrder from '@/api/Order'

export default function ({ store, $axios }, options = {}) {
  options = {
    designerDiscountApplicationEnabled: true,
    promoCodeApplicationEnabled: true,
    ...options
  }

  function couponCodeApplicationParams () {
    let params = { order_id: store.getters['order/getOrderId'] }

    if (shouldApplyPromoCode()) {
      return { ...params, code: store.getters['order/getPromoCode'] }
    }

    if (shouldApplyDesignerDiscount()) {
      return { ...params, designers_default: true }
    }
  }

  function shouldApplyCouponCode () {
    return (
      !!store.getters['order/getOrder'] &&
      !store.getters['order/getDiscountsAdjustments'].length &&
      (shouldApplyDesignerDiscount() || shouldApplyPromoCode())
    )
  }

  function shouldApplyDesignerDiscount () {
    return (
      options.designerDiscountApplicationEnabled &&
      store.getters['auth/signedDesigner'] &&
      !store.getters['user/getDesignerDiscountApplied']
    )
  }

  function shouldApplyPromoCode () {
    return options.promoCodeApplicationEnabled && !!store.getters['order/getPromoCode']
  }

  return function applyCouponCode () {
    if (!shouldApplyCouponCode()) return

    let params = couponCodeApplicationParams()

    ApiOrder.applyCouponCode($axios, params)
      .then(response => {
        if (params.designers_default) store.dispatch('user/setDesignerDiscountApplied', true)
        store.dispatch('order/setOrder', response.data.data)
      })
      .catch(error => {
        console.error(error)
      })
  }
}