export default {
    data () {
        return {
            oldDocumentOverflow: '',
            oldBodyOverflow: '',
            modalConditionNames: []
        }
    },
    mounted() {
        this.setWatchers()
    },
    methods: {
        setWatchers () {
            this.modalConditionNames.forEach(name => { this.$watch(name, this.toggleDocumentOverflow) })
        },
        toggleDocumentOverflow (newValue) {
            let documentStyle = document.documentElement.style
            let bodyStyle = document.body.style

            if (newValue) {
                this.oldDocumentOverflow = documentStyle.overflow
                this.oldBodyOverflow = bodyStyle.overflow
                documentStyle.overflow = 'hidden'
                bodyStyle.overflow = 'hidden'
            } else {
                documentStyle.overflow = this.oldDocumentOverflow
                bodyStyle.overflow = this.oldBodyOverflow
            }
        }
    }
}