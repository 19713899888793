
export const state = () => ({
        active: false,
        url: ''
    })
export const getters = {
        getState: state =>  state.active,
        getImage: state =>  state.url
    }
export const mutations = {
        show(state, payload) {
            state.url = payload,
            state.active = true
        },
        hide(state) {
            state.url = '',
            state.active = false
        }
    }
export const actions = {
        showPreview ({ commit }, url) {
            commit('show', url)
        },
        hidePreview ({ commit }) {
            commit('hide')
        }
    }