<template>
    <div class="recommended-styles-carousel">
        <p class="recommended-styles-carousel__title">Recommended Styles</p>
        <div class="recommended-styles-carousel__container">
            <component
                v-if="items.length > 0"
                :is="activeComponent"
                :items="items"
            />
        </div>

        <scout-button
            label="Shop Bestsellers"
            color="secondary"
            :to="{ name: 'products' }"
        />
    </div>
</template>

<script>
import ScoutButton from '@/components/ScoutButton'
import recommend from '@algolia/recommend'

export default {
    name: 'RecommendedStylesCarousel',
    components: {
        ScoutButton,
        ProductsCarouselDesktop: () => import('@/components/products/ProductsCarouselDesktop'),
        ProductsCarouselMobile: () => import('@/components/products/ProductsCarouselMobile')
    },
    data() {
        return {
            items: [],
            client: null
        }
    },
    computed: {
        activeComponent() {
            return this.$device.isMobile
                ? 'ProductsCarouselMobile'
                : 'ProductsCarouselDesktop'
        }
    },
    methods: {
        async fetchTrendingItems() {
            const response = await this.client.getTrendingItems([
                {
                    indexName: this.$config.ALGOLIA_VARIANTS_INDEX_NAME,
                    maxRecommendations: 8
                }
            ])

            const { results = [] } = response
            this.items = results.flatMap(record => record.hits)
        }
    },
    mounted() {
        this.client = recommend(
            this.$config.ALGOLIA_APPLICATION_ID,
            this.$config.ALGOLIA_API_KEY
        )
        this.fetchTrendingItems()
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/variables.scss';

.recommended-styles-carousel {
    padding-right: 0;
    padding-left: 2rem;
    margin: 0 auto;


    &__btn {
        display: inline-block;
        padding: 1rem;
        color: $gunmetal;
        font-family: 'MotivaSans';
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 0.48px;
        border: 0.5px solid #2B2A2E;
    }

    &__container {
        min-height: 400px;
        margin-bottom: 13px;
    }

    &__title {
        color: $gunmetal;
        text-align: left;
        font-family: 'Tenez';
        font-size: 30px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.9px;
    }

    @media (min-width: 1024px) {
        padding: 0;
        text-align: center;

        &__title {
            text-align: center;
        }
    }
}
</style>
