import * as api_config from '../config/api_config'

export default {
    getUser(axios) {
        return axios.get(`${api_config.ADDRESS}/user`, {headers: api_config.HEADERS});
    },
    updateUser(axios, payload) {
        return axios.patch(`${api_config.ADDRESS}/user`, payload, {headers: api_config.HEADERS});
    },
    initialCheck(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/user/initial_check`, payload, {headers: api_config.HEADERS});
    },
    forgotPassword(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/user/forgot`, payload, {headers: api_config.HEADERS});
    },
    resetPassword(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/user/reset`, payload, {headers: api_config.HEADERS});
    },
    createDesigner(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/user/create_designer`, payload, {headers: api_config.HEADERS})
    },
    changePassword(axios, payload) {
        return axios.patch(`${api_config.ADDRESS}/user/change_password`, payload, {headers: api_config.HEADERS});
    },
    applyAddress(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/user/set_address`, payload, {headers: api_config.HEADERS});
    },
    applyBillingAddress(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/user/set_billing_address`, payload, {headers: api_config.HEADERS});
    },
    getAddress(axios) {
        return axios.get(`${api_config.ADDRESS}/user/get_address`, {headers: api_config.HEADERS});
    },
    getBillingAddress(axios) {
        return axios.get(`${api_config.ADDRESS}/user/get_billing_address`, {headers: api_config.HEADERS});
    },
    getItems(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/user/get_items`, payload, {headers: api_config.HEADERS})
    },
    addEmailToNewsletter(axios, payload) {
        return axios.post(`${api_config.ADDRESS}/user/add_to_newsletter`, payload, {headers: api_config.HEADERS});
    },
    updateNotifications(axios, payload) {
        return axios.patch(`${api_config.ADDRESS}/user/update_notifications`, payload, {headers: api_config.HEADERS})
    },
    getShippingAddressAttributes(axios) {
        return axios.get(`${api_config.ADDRESS}/user/shipping_address_attributes`, {headers: api_config.HEADERS})
    },
    getBillingAddressAttributes(axios) {
        return axios.get(`${api_config.ADDRESS}/user/billing_address_attributes`, {headers: api_config.HEADERS})
    },
    getCheckAddedToNewsletter(axios, params) {
        const url = `${api_config.ADDRESS}/user/check_added_to_newsletter`
        const options = { params, headers: api_config.HEADERS }

        return axios.get(url, options)
    },
    check (axios, params) {
        const url = `${api_config.ADDRESS}/user/check`
        const options = { params, headers: api_config.HEADERS }

        return axios.get(url, options)
    }
}
