import Vue from 'vue'
import aa from 'search-insights'

function getInitParams({ ALGOLIA_APPLICATION_ID, ALGOLIA_API_KEY }) {
  return {
    appId: ALGOLIA_APPLICATION_ID,
    apiKey: ALGOLIA_API_KEY,
    useCookie: true,
  }
}

const onUserTokenChangeParams = {
  immediate: true
}

function onUserTokenChange (userToken) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ algoliaUserToken: userToken })
}

function setUserToken (userToken) {
  if (!userToken) { return }

  aa('setUserToken', userToken)
}

export default ({ store, $cookies, $config }) => {
  aa('init', getInitParams($config))
  aa('onUserTokenChange', onUserTokenChange, onUserTokenChangeParams)
  setUserToken($cookies.get('sanUID'))

  store.subscribe((mutation) => {
    if (mutation.type === 'auth/setUser') {
      setUserToken($cookies.get('sanUID'))
    }
  })

  Vue.prototype.$searchInsights = aa
}
