import * as api_config from "@/config/api_config";

export default {
  storeConversionEvent(axios, payload) {
    const url = `${api_config.ADDRESS}/conversion_events/store`
    const options = { headers: api_config.HEADERS }

    return axios.post(url, payload, options)
  }
}

