import * as api_config from '../config/api_config'

export default {
    signIn(axios, credentials, additional_params = {}) {
        let path = '/users/sign_in'
        return axios.post(`${api_config.AUTHADDRESS}${path}`,
            {user: credentials, ...additional_params},
            {headers: api_config.HEADERS})
    },
    signUp(axios, credentials) {
        let path = '/users'
        return axios.post(`${api_config.AUTHADDRESS}${path}`,
            {user: credentials},
            {headers: api_config.HEADERS})
    },
    signOut(axios, token) {
        let path = '/users/sign_out'
        return axios.delete(`${api_config.AUTHADDRESS}${path}`, {headers: api_config.HEADERS})
    },
    facebookSignIn(axios, params) {
      return axios.get(`users/auth/facebook/callback`, {
          params: params,
          baseURL: '/'
      })
  }
}