<template>

    <transition name="fade">
        <div class="toast-wrapper">
            <div class="toast">
                <div v-if="getHtml" class="info" v-html="getHtml"/>
                <div v-else class="info">
                    {{ getMessage }}
                </div>
            </div>
        </div>
    </transition>

</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        name: 'toast-info',
        props: {
            timeout: {
                type: Number,
                default: 5000
            }
        },
        computed: {
            ...mapGetters('toast', ['getHtml', 'getMessage'])
        },
        created() {
            setTimeout(() => {
                this.$store.dispatch('toast/hideToast')
            }, this.timeout)
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/../shared/assets/stylesheets/animations/fade';

    .toast-wrapper {
        position: fixed;
        top: 70px;
        left: 245px;
        right: 0;
        z-index: 100000;
        display: flex;
    }

    .toast {
        width: 1100px;
        margin: auto;
        display: flex;
        justify-content: flex-end;

        .info {
            margin: 0;
            max-width: 500px;
            padding: 13px 25px;
            border-radius: 5px;
            box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
            background-color: #4e4e4e;

            font-family: OpenSans;
            font-size: 1.6rem;
            line-height: 1.38;
            letter-spacing: 0.8px;
            font-weight: normal;
            text-align: center;
            color: #fff;
        }
    }
</style>