<template>
    <nuxt-link
        v-if="!!to"
        class="scout-button"
        :class="classes"
        :to="to"
    >
        <span>
            <slot>
                {{ label }}
            </slot>
        </span>
    </nuxt-link>
    <button
        v-else
        class="scout-button"
        type="button"
        :class="classes"
        @click="$emit('click', $event)"
    >
        <slot>
            <span>
                {{ label }}
            </span>
        </slot>
    </button>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: 'primary'
        },
        to: {
            type: [String, Object],
            default: null
        },
        disable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes() {
            return {
                'scout-button--primary': !this.color || this.color === 'primary',
                'scout-button--secondary': this.color === 'secondary',
                'scout-button--disabled': !!this.disable
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/variables.scss';

.scout-button {
    color: $color-white;
    cursor: pointer;
    padding: 12px 20px;
    font-family: 'MotivaSans';
    font-size: 1.5rem;
    font-style: normal;
    line-height: normal;
    font-weight: inherit;
    border-width: 0;
    text-transform: uppercase;

    &--primary {
        color: $color-white;
        background: $gunmetal;

        &:hover {
            &:not(.scout-button--disabled) {
                color: $gunmetal;
                background: $color-white;
                border: 1px solid $gunmetal;
            }
        }
    }

    &--secondary {
        color: $gunmetal;
        background: $color-white;
        border: 1px solid $gunmetal;

        &:hover {
            &:not(.scout-button--disabled) {
                color: $color-white;
                background: $color-white;
                background: $gunmetal;
            }
        }
    }

    &--disabled {
        opacity: .5;
        cursor: not-allowed;

        span {
            pointer-events: none;
        }
    }
}
</style>
