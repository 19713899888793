<template>
    <div class="errors-page">
        <div class="errors-page__hero">
            <picture>
                <source
                    srcset="@/assets/images/errorpage/mobile.jpg"
                    media="(max-width: 1024px)"
                >
                <img
                    class="errors-page__background"
                    src="@/assets/images/errorpage/desktop.jpg"
                />
            </picture>
            <div class="errors-page__content">
                <div class="errors-page__title">{{ title }}</div>
                <div class="errors-page__status">{{ status }}</div>
                <div class="errors-page__text">{{ description }}</div>
            </div>
        </div>

        <div class="errors-page__container grid-wrapper">
            <recommended-styles-carousel />
        </div>

        <product-support-section class="errors-page__support" />
    </div>
</template>

<script>
import RecommendedStylesCarousel from '@/components/error/RecommendedStylesCarousel.vue'
import ProductSupportSection from '@/components/products/ProductSupportSection'

export default {
    name: 'ErrorsPage',
    components: {
        RecommendedStylesCarousel,
        ProductSupportSection,

    },
    props: ['error'],
    data() {
        return {
            errors: {
                404: {
                    title: 'Uh oh!\n This room is empty...',
                    status: '404 Error | Page Not Found',
                    description: 'We couldn’t track down the page you’re looking for, but we’ve\n' +
                        'provided some best-selling luxury styles below to get you back on the right track.'
                },
                500: {
                    title: 'Oops... Server Error',
                    description: 'This page is temporarily unavailable. Our developers are working on it!\n' +
                        'You can try use one of the links below.'
                }
            }
        }
    },
    computed: {
        title() {
            return this.errors[this.error.statusCode].title
        },
        status() {
            return this.errors[this.error.statusCode].status || this.error.statusCode
        },
        description() {
            return this.errors[this.error.statusCode].description
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/stylesheets/variables";

.errors-page {
    &__hero {
        text-align: center;
        position: relative;
        height: 405px;
    }

    &__content {
        color: $color-white;
        position: relative;
        z-index: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__container {
        margin-top: 57px !important;
    }

    &__title {
        white-space: pre-wrap;
        font-family: 'Tenez Light';
        font-size: 36px;
        font-style: italic;
        line-height: normal;
        text-shadow: 0px 0px 2px $color-black;

        margin-bottom: 2.8125rem;
    }

    &__status {
        white-space: pre-wrap;
        font-family: 'MotivaSans SemiBold';
        font-size: 15px;
        line-height: normal;
        text-shadow: 0px 0px 2px $color-black;
        text-transform: uppercase;

        margin-bottom: 1.5rem;
    }

    &__text {
        white-space: pre-wrap;
        font-family: 'OpenSans';
        font-size: 15px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0.51px;
        text-shadow: 0px 0px 2px $color-black;
        padding: 3rem;
    }

    &__background {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    &__support {
        overflow: hidden;
        transform: translateY(50px);
    }

    @media (min-width: 1024px) {
        &__hero {
            height: 575px;
        }

        &__title {
            font-size: 45px;
            white-space: normal;
        }

        &__status {
            font-size: 15px;
        }

        &__text {
            font-size: 17px;
        }
    }

    @media (min-width: 1921px) {
        &__background {
            object-fit: contain;
        }
    }
}
</style>