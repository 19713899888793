export default ({ $config: {CORDIAL_TRACK_URL, CORDIAL_CONNECT_URL, CORDIAL_COOKIE_DOMAIN, CORDIAL_SCRIPT_URL}}) => {
  (function(C,O,R,D,I,A,L){
    C.CordialObject=I,C[I]=C[I]||function(){(C[I].q=C[I].q||[]).push(arguments)};
    C[I].l=1*new Date,C[I].q=[],A=O.createElement(R);
    L=O.getElementsByTagName(R)[0],A.async=1,A.src=D,L.parentNode.insertBefore(A,L);
  })(window, document, "script", CORDIAL_SCRIPT_URL, "crdl");

  crdl("connect", "scoutandnimble", {
    trackUrl: CORDIAL_TRACK_URL,
    connectUrl: CORDIAL_CONNECT_URL,
    cookieDomain: CORDIAL_COOKIE_DOMAIN,
    cookieLife: 365
  });
}