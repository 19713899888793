import { startsWith } from 'lodash'
import { updateFirstReferrerCookie, updateLastReferrerCookie } from '@/utils/utils'

export default function ({ req, app }) {
  if (!process.server) return

  const referrer = req.headers.referer || req.headers.referrer || null
  if (!referrer) return
  if (startsWith(referrer, app.$config.baseUrl)) return

  updateLastReferrerCookie(app, referrer)

  if (!!app.$cookies.get('sanFR')) return

  updateFirstReferrerCookie(app, referrer)
}
