<template>
  <div class="header-wrapper">
    <div ref="headerWrapper" class="header-wrapper__inner" :class="{ top: pageTop <= 0 }" :style="headerWrapperStyle">
      <slot/>
    </div>

    <div class="header-wrapper__placeholder" :style="{ height: headerWrapperStyle.position !== 'static' ? `${headerHeight}px` : 0 }"/>
  </div>
</template>

<script>
  export default {
    name: 'HeaderWrapper',
    data() {
      return {
        pageTop: 0,
        headerVisible: true,
        headerWrapperStyle: {
          top: 0,
          position: 'static',
          visibility: 'visible'
        }
      }
    },
    computed: {
      headerHeight () {
        return this.$refs.headerWrapper.offsetHeight
      }
    },
    watch: {
      pageTop (newValue, oldValue) {
        const headerWrapperTop = parseInt(this.headerWrapperStyle.top)

        // Scroll down
        if (newValue > oldValue) {
          if (this.headerWrapperStyle.position === 'fixed') {
            this.headerWrapperStyle.top = `${newValue}px`
            this.headerWrapperStyle.position = 'absolute'
          }
        }

        // Scroll up
        if (newValue < oldValue) {
          if (headerWrapperTop >= newValue) {
            this.headerWrapperStyle.top = 0
            this.headerWrapperStyle.position = newValue <= 0 ? 'static' : 'fixed'
        } else if (this.headerWrapperStyle.position !== 'fixed' && headerWrapperTop < newValue - this.headerHeight) {
            this.headerWrapperStyle.top = `${newValue - this.headerHeight}px`
            this.headerWrapperStyle.position = 'absolute'
          }
        } 
      }
    },
    mounted () {
      this.setEventListeners()
    },
    beforeDestroy() {
      this.removeEventListeners()
    },
    methods: {
      setEventListeners () {
        window.addEventListener('scroll', this.calculateHeaderPosition)
      },
      removeEventListeners () {
        window.removeEventListener('scroll', this.calculateHeaderPosition)
      },
      calculateHeaderPosition () {
        this.pageTop = window.top.scrollY + document.body.offsetTop
      }
    }
  }
</script>