const DEFAULT_MENUS = ['main_desktop', 'main_right_desktop', 'footer_desktop', 'main_mobile', 'footer_mobile', 'shop_by_category']

export const  state = () =>  ({
        menus: {}
    })
export const    getters = {
        getMenus: state => {
            return state.menus
        },
        getMenu: state => menuHandle => {
            return state.menus[menuHandle]
        }
    }
export const     mutations = {
        setMenus (state, payload) {
            state.menus = payload
        }
    }

