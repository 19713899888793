export default ({ $config: { GA4_MEASUREMENT_ID } }) => {
    window.dataLayer = window.dataLayer || []

    function gtag (){
        dataLayer.push(arguments)
    }

    gtag('js', new Date())
    gtag('config', GA4_MEASUREMENT_ID)
}
