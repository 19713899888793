export const state = () => ({
    active: false,
    message: '',
    html: ''
})
export const getters = {
    getState: state =>  state.active,
    getMessage: state =>  state.message,
    getHtml: state =>  state.html
}
export const mutations = {
    show(state, payload) {
        typeof payload === 'object' ? state.html = payload.html : state.message = payload
        state.active = true
    },
    hide(state) {
        state.message = ''
        state.html = ''
        state.active = false
    }
}
export const actions = {
    showToast ({ commit }, payload) {
        commit('show', payload)
    },
    hideToast ({ commit }) {
        commit('hide')
    }
}