
export const state = () => ({
        handle404: false,
        handle500: true
    })

export const getters = {
        getConfig: state => state,
        getHandle404: state => {
            return state.handle404
        },
        getHandle500: state => {
            return state.handle500
        }
    }
export const mutations = {
        setConfig (state, payload) {
            const { handle404, handle500 } = { ...state, ...payload }

            state.handle404 = handle404
            state.handle500 = handle500
        }
    }
export const actions = {
        setConfig({ commit }, payload) {
            commit('setConfig', payload)
        }
    }
