import Vue from 'vue'

export default ({ app, $config: { APP_ENV, PRODUCTION } }) => {
  app.router.beforeEach((to, _from, next) => {
    app.store.dispatch('globalData/setNavigationDirection', 'forward')
    if (APP_ENV === 'staging' && !app.store.getters['auth/getSignedInStaging'] && to.name !== 'staging_auth') {
      app.store.dispatch('globalData/setRedirectTo', to)
      next({name: 'staging_auth'})
    }
    if (to.meta.auth && !app.store.getters['auth/signedIn']) {
      if (to.name !== 'staging_auth') {
        app.store.dispatch('globalData/setRedirectTo', to)
      }

      next({name: 'login'})
    } else if (to.meta.auth === 'admin' && !app.store.getters['auth/signedAdmin']) {
      next({name: 'index'})
    }
    next()
  })

  app.router.afterEach((_to, _from) => {
    Vue.nextTick(() => {
      if(process.client && PRODUCTION) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ 'event': 'optimize.routeUpdated' })

        fbq('track', 'PageView')
      }
    })

    // if (to.name == 'product_path') {
        // Cordial.methods.trackEvent('browse', {
        //     'type': 'product',
        //     'name': to.query.variant_id
        // })
    // } else if (to.name == 'products_path' && to.query.search) {
    //     Cordial.methods.trackEvent('browse', {
    //         'type': 'search',
    //         'name': to.query.search.keyword
    //     })
    // } else if (to.name == 'brand_path') {
    //     Cordial.methods.trackEvent('browse', {
    //         'type': 'brand',
    //         'name': to.params.name
    //     })
    // } else if (to.name == 'categories_path') {
    //     Cordial.methods.trackEvent('browse', {
    //         'type': 'category',
    //         'name': to.params.name
    //     })
    // } else if (to.name == 'room_path') {
    //     Cordial.methods.trackEvent('browse', {
    //         'type': 'room',
    //         'name': to.params.slug
    //     })
    // }
  })
}