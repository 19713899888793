export default function(to, _from, savedPosition) {
    const shouldScrollTop = [
      'categories', 'products', 'brands-name', 'collections', 'search_results', 'brands-name-category',
      'collections-slug-category'
    ].includes(to.name)

    if (shouldScrollTop) {
      return { x: 0, y: 0 }
    }

    if (savedPosition) {
      return savedPosition
    }

    if (to.hash) {
      return {selector: to.hash}
    }

    if (to.name != 'category-rooms-name' && to.name != 'user-details') {
      return { x: 0, y: 0 }
    }
}
