export const state = () => ({
        products_display: 'col4',
        search_reload: false,
        redirect_to: null,
        show_mobile_top_bar: true,
        layout: null,
        navigation_direction: 'forward'
    })
export const getters = {
        getProductsDisplay: state => {
            return state.products_display
        },
        getSearchReload: state => {
            return state.search_reload
        },
        getRedirectTo: state => {
            return state.redirect_to
        },
        getShowMobileTopBar: state => {
            return state.show_mobile_top_bar
        },
        getLayout: state => {
            return state.layout
        },
        getNavigationDirection: state => {
            return state.navigation_direction
        }
    }
export const mutations = {
        setProductsDisplay(state, payload) {
            state.products_display = payload
        },
        setSearchReload(state, payload) {
            state.search_reload = payload
        },
        setRedirectTo(state, payload) {
            state.redirect_to = payload
        },
        setShowMobileTopBar(state, payload) {
            state.show_mobile_top_bar = payload
        },
        setLayout(state, payload) {
            state.layout = payload
        },
        setNavigationDirection(state, payload) {
            state.navigation_direction = payload
        }
    }
export const actions = {
        setProductsDisplay({ commit }, payload) {
            commit('setProductsDisplay', payload)
        },
        searchReload({ commit }, payload) {
            commit('setSearchReload', payload)
        },
        setRedirectTo({ commit }, payload) {
            commit('setRedirectTo', payload)
        },
        clearRedirectTo({ commit }) {
            commit('setRedirectTo', null)
        },
        setShowMobileTopBar({ commit }, payload) {
            commit('setShowMobileTopBar', payload)
        },
        setLayout({ commit }, payload) {
            commit('setLayout', payload)
        },
        setNavigationDirection({ commit }, payload) {
            commit('setNavigationDirection', payload)
        }
    }
