<template>
    <div class="carousel">
        <skeleton-box v-if="!slidesLoaded"
                      :height="$device.isMobile ? sizes.mobile.height : sizes.desktop.height"
                      has-skeleton
                      width="100%" />

        <template v-else>
            <div v-if="showControls" class="prev">
                <i class="fas fa-chevron-left" @click="prev"></i>
            </div>

            <transition name="fade">
                <a v-if="slides.length > 0" :href="currentUrl">
                    <picture>
                        <source v-if="currentMobileImageUrl" :srcset="currentMobileImageUrl" media="(max-width: 600px)">
                        <nuxt-img quality="80"
                                  preload
                                  :src="currentImage"
                                  :alt="currentAlt"
                                  :height="sizes.desktop.height"
                                  :width="sizes.desktop.width"
                                  :class="{ 'empty-class-for-gtm': $device.isMobile }" />
                    </picture>
                </a>
            </transition>

            <div v-if="showControls" class="next">
                <i class="fas fa-chevron-right" @click="next"></i>
            </div>
        </template>
    </div>
</template>

<script>
    import SkeletonBox from '@/components/SkeletonBox'

    export default {
        name: 'carousel',
        components: {
            SkeletonBox
        },
        props: {
            hasSkeleton: {
                type: Boolean,
                default: true
            },
            sizes: {
                type: Object,
                default: () => ({
                    desktop: {
                        width: '1920px',
                        height: '500px'
                    },
                    mobile: {
                        width: '400px',
                        height: '350px'
                    }
                })
            },
            slidesLoaded: {
                type: Boolean,
                default: false
            },
            slides: {
                type: Array,
                default: []
            },
            showControls: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                currentIndex: 0,
                interval: null
            };
        },
        computed: {
            currentImage() {
                return this.slides[this.currentIndex].image.url
            },
            currentMobileImage () {
                return this.slides[this.currentIndex].mobile_image.url
            },
            currentMobileImageUrl () {
                if(this.currentMobileImage){
                    return this.$img(this.currentMobileImage, { width: this.sizes.mobile.width, quality: '100' })
                }
            },
            currentUrl() {
                return this.slides[this.currentIndex].path
            },
            currentAlt () {
                return this.slides[this.currentIndex].alternative_text
            }
        },
        methods: {
            next() {
                if (this.currentIndex + 1 < this.slides.length) {
                    this.currentIndex++
                } else {
                    this.currentIndex = 0
                }
            },
            prev() {
                if (this.currentIndex == 0) {
                    this.currentIndex = this.slides.length - 1
                } else {
                    this.currentIndex--
                }
            },
            autoPlay() {
                this.interval = setInterval(() => {
                    this.next()
                }, 5000)
            }
        },
        beforeMount() {
            this.autoPlay()
        },
        beforeDestroy () {
            clearInterval(this.interval)
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/stylesheets/variables.scss';
    @import '@/assets/stylesheets/animations/fade';

    .vue-content-placeholders-img {
        height: 300px;
        margin-bottom: 15px;
    }

    .carousel{
        text-align: center;
        position: relative;
        width: 100%;

        img{
            height: auto;
            max-width: 100%;
        }

        .prev{
            position: absolute;
            left: 0;
            top: 0;
            z-index: 999;
            height: 100%;
            width: 50px;
            text-align: left;
        }

        .next{
            position: absolute;
            right: 0;
            top: 0;
            z-index: 999;
            height: 100%;
            width: 50px;
            text-align: center;
        }

        i{
            font-size: 3.2rem;
            cursor: pointer;
            color: #fff;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            text-shadow: -2px 0 #333, 0 2px #333, 2px 0 #333, 0 -2px #333;
        }
    }

</style>
