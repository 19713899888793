import Vue from 'vue'
import { VTooltip } from 'floating-vue'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

Vue.directive('tooltip', VTooltip)
Vue.use(FloatingVue, {
    themes: {
        'sn-popper': {
            $extend: 'dropdown'
        },
        'sn-popper-alt': {
            $extend: 'dropdown'
        }
    }
})