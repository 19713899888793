import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0e2d8fff = () => interopDefault(import('../pages/40-k-sweepstakes/index.vue' /* webpackChunkName: "pages/40-k-sweepstakes/index" */))
const _ac3a7cce = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _6ed7c26d = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _bd1597da = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _6d065c04 = () => interopDefault(import('../pages/collections/index.vue' /* webpackChunkName: "pages/collections/index" */))
const _e9a519de = () => interopDefault(import('../pages/designers/index.vue' /* webpackChunkName: "pages/designers/index" */))
const _0c224900 = () => interopDefault(import('../pages/financing/index.vue' /* webpackChunkName: "pages/financing/index" */))
const _6db9b320 = () => interopDefault(import('../pages/forgot_password/index.vue' /* webpackChunkName: "pages/forgot_password/index" */))
const _5ebeddfe = () => interopDefault(import('../pages/four-hands-art-studio-designer-feature/index.vue' /* webpackChunkName: "pages/four-hands-art-studio-designer-feature/index" */))
const _7a6b0afc = () => interopDefault(import('../pages/four-hands-eco-friendly-furniture.vue' /* webpackChunkName: "pages/four-hands-eco-friendly-furniture" */))
const _42a1227d = () => interopDefault(import('../pages/holiday-collection.vue' /* webpackChunkName: "pages/holiday-collection" */))
const _dc32db0a = () => interopDefault(import('../pages/info/index.vue' /* webpackChunkName: "pages/info/index" */))
const _7e6ea552 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _63563050 = () => interopDefault(import('../pages/new_payment/index.vue' /* webpackChunkName: "pages/new_payment/index" */))
const _2fcdfdd3 = () => interopDefault(import('../pages/order_confirmation/index.vue' /* webpackChunkName: "pages/order_confirmation/index" */))
const _1604aa9e = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _14e96a21 = () => interopDefault(import('../pages/rooms/index.vue' /* webpackChunkName: "pages/rooms/index" */))
const _1fe10e6c = () => interopDefault(import('../pages/search_results/index.vue' /* webpackChunkName: "pages/search_results/index" */))
const _e9a74436 = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _0bab2b76 = () => interopDefault(import('../pages/staging_auth.vue' /* webpackChunkName: "pages/staging_auth" */))
const _12b6ca92 = () => interopDefault(import('../pages/trade_program/index.vue' /* webpackChunkName: "pages/trade_program/index" */))
const _53331695 = () => interopDefault(import('../pages/user.vue' /* webpackChunkName: "pages/user" */))
const _4635d06a = () => interopDefault(import('../pages/user/details/index.vue' /* webpackChunkName: "pages/user/details/index" */))
const _59351146 = () => interopDefault(import('../pages/user/history_orders/index.vue' /* webpackChunkName: "pages/user/history_orders/index" */))
const _735c7ef6 = () => interopDefault(import('../pages/user/link_order/index.vue' /* webpackChunkName: "pages/user/link_order/index" */))
const _137fb761 = () => interopDefault(import('../pages/user/my_orders/index.vue' /* webpackChunkName: "pages/user/my_orders/index" */))
const _563f3a4c = () => interopDefault(import('../pages/user/processing_orders/index.vue' /* webpackChunkName: "pages/user/processing_orders/index" */))
const _2623a7f4 = () => interopDefault(import('../pages/user_billing_address.vue' /* webpackChunkName: "pages/user_billing_address" */))
const _7b8a59c0 = () => interopDefault(import('../pages/user_change_password.vue' /* webpackChunkName: "pages/user_change_password" */))
const _9ca3183a = () => interopDefault(import('../pages/user_contact_information.vue' /* webpackChunkName: "pages/user_contact_information" */))
const _5ed150ea = () => interopDefault(import('../pages/user_dashboard.vue' /* webpackChunkName: "pages/user_dashboard" */))
const _7f222806 = () => interopDefault(import('../pages/user_shipping_address.vue' /* webpackChunkName: "pages/user_shipping_address" */))
const _1e595d28 = () => interopDefault(import('../pages/checkout/old.vue' /* webpackChunkName: "pages/checkout/old" */))
const _4ef17208 = () => interopDefault(import('../pages/designers/account_verifing/index.vue' /* webpackChunkName: "pages/designers/account_verifing/index" */))
const _aa959ec2 = () => interopDefault(import('../pages/designers/account_verify/index.vue' /* webpackChunkName: "pages/designers/account_verify/index" */))
const _8fc060a8 = () => interopDefault(import('../pages/designers/signup/index.vue' /* webpackChunkName: "pages/designers/signup/index" */))
const _74000856 = () => interopDefault(import('../pages/financing/old.vue' /* webpackChunkName: "pages/financing/old" */))
const _454d29ae = () => interopDefault(import('../pages/gift_cards/gift_card_balance/index.vue' /* webpackChunkName: "pages/gift_cards/gift_card_balance/index" */))
const _123fe53b = () => interopDefault(import('../pages/rooms/categories/_name/index.vue' /* webpackChunkName: "pages/rooms/categories/_name/index" */))
const _2fe9f482 = () => interopDefault(import('../pages/rooms/categories/_category/new/index.vue' /* webpackChunkName: "pages/rooms/categories/_category/new/index" */))
const _ff15124a = () => interopDefault(import('../pages/40-k-sweepstakes/_step/index.vue' /* webpackChunkName: "pages/40-k-sweepstakes/_step/index" */))
const _5eec9df4 = () => interopDefault(import('../pages/brands/_name/index.vue' /* webpackChunkName: "pages/brands/_name/index" */))
const _0a7079e2 = () => interopDefault(import('../pages/delivery_date_requests/_id/index.vue' /* webpackChunkName: "pages/delivery_date_requests/_id/index" */))
const _1efd0ef7 = () => interopDefault(import('../pages/designers/_username/index.vue' /* webpackChunkName: "pages/designers/_username/index" */))
const _090c67a9 = () => interopDefault(import('../pages/my_orders/_id.vue' /* webpackChunkName: "pages/my_orders/_id" */))
const _ff592e20 = () => interopDefault(import('../pages/pages/_url_handler.vue' /* webpackChunkName: "pages/pages/_url_handler" */))
const _00571d2c = () => interopDefault(import('../pages/products/_slug/index.vue' /* webpackChunkName: "pages/products/_slug/index" */))
const _0263a7b8 = () => interopDefault(import('../pages/reset_password/_token.vue' /* webpackChunkName: "pages/reset_password/_token" */))
const _2ef1889c = () => interopDefault(import('../pages/rooms/_slug/index.vue' /* webpackChunkName: "pages/rooms/_slug/index" */))
const _c1619928 = () => interopDefault(import('../pages/link_order/_id/accept/index.vue' /* webpackChunkName: "pages/link_order/_id/accept/index" */))
const _62a4b881 = () => interopDefault(import('../pages/products/_slug/old.vue' /* webpackChunkName: "pages/products/_slug/old" */))
const _f2c53916 = () => interopDefault(import('../pages/warehouse_quote/_id/form/index.vue' /* webpackChunkName: "pages/warehouse_quote/_id/form/index" */))
const _5d1cfa10 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/40-k-sweepstakes",
    component: _0e2d8fff,
    meta: {"name":"40kSweepstakesView"},
    name: "40-k-sweepstakes"
  }, {
    path: "/brands",
    component: _ac3a7cce,
    meta: {"name":"brands-view"},
    name: "brands"
  }, {
    path: "/cart",
    component: _6ed7c26d,
    meta: {"name":"cart-view"},
    name: "cart"
  }, {
    path: "/checkout",
    component: _bd1597da,
    meta: {"name":"Checkout"},
    name: "checkout"
  }, {
    path: "/collections",
    component: _6d065c04,
    meta: {},
    name: "collections"
  }, {
    path: "/designers",
    component: _e9a519de,
    meta: {"name":"Designers"},
    name: "designers"
  }, {
    path: "/financing",
    component: _0c224900,
    meta: {"name":"Financing"},
    name: "financing"
  }, {
    path: "/forgot_password",
    component: _6db9b320,
    meta: {},
    name: "forgot_password"
  }, {
    path: "/four-hands-art-studio-designer-feature",
    component: _5ebeddfe,
    meta: {"name":"FhArtStudio"},
    name: "four-hands-art-studio-designer-feature"
  }, {
    path: "/four-hands-eco-friendly-furniture",
    component: _7a6b0afc,
    meta: {"name":"FourHandsEcoFriendlyFurniture"},
    name: "four-hands-eco-friendly-furniture"
  }, {
    path: "/holiday-collection",
    component: _42a1227d,
    meta: {"name":"HolidayCollection"},
    name: "holiday-collection"
  }, {
    path: "/info",
    component: _dc32db0a,
    meta: {"name":"Info","props":{"infoText":{"default":null}}},
    props: true,
    name: "info"
  }, {
    path: "/login",
    component: _7e6ea552,
    meta: {"name":"login-view"},
    name: "login"
  }, {
    path: "/new_payment",
    component: _63563050,
    meta: {"name":"PaymentsNew"},
    name: "new_payment"
  }, {
    path: "/order_confirmation",
    component: _2fcdfdd3,
    meta: {"name":"order-confirmation-view"},
    name: "order_confirmation"
  }, {
    path: "/products",
    component: _1604aa9e,
    meta: {"name":"products-view"},
    name: "products"
  }, {
    path: "/rooms",
    component: _14e96a21,
    meta: {"name":"RoomsView"},
    name: "rooms"
  }, {
    path: "/search_results",
    component: _1fe10e6c,
    meta: {"name":"SearchResults","inject":["appData"]},
    name: "search_results"
  }, {
    path: "/signup",
    component: _e9a74436,
    meta: {},
    name: "signup"
  }, {
    path: "/staging_auth",
    component: _0bab2b76,
    meta: {"name":"StagingAuth"},
    name: "staging_auth"
  }, {
    path: "/trade_program",
    component: _12b6ca92,
    meta: {"name":"Designers"},
    name: "trade_program"
  }, {
    path: "/user",
    component: _53331695,
    meta: {"auth":true},
    name: "user",
    children: [{
      path: "details",
      component: _4635d06a,
      meta: {"name":"MyPersonalDetails","auth":true},
      name: "user-details"
    }, {
      path: "history_orders",
      component: _59351146,
      meta: {"name":"MyOrders"},
      name: "user-history_orders"
    }, {
      path: "link_order",
      component: _735c7ef6,
      meta: {"name":"LinkOrder","auth":true},
      name: "user-link_order"
    }, {
      path: "my_orders",
      component: _137fb761,
      meta: {"name":"MyOrders"},
      name: "user-my_orders"
    }, {
      path: "processing_orders",
      component: _563f3a4c,
      meta: {"name":"MyOrdersProcessing"},
      name: "user-processing_orders"
    }]
  }, {
    path: "/user/billing_address",
    component: _2623a7f4,
    meta: {"auth":true},
    name: "user-billing_address"
  }, {
    path: "/user/change_password",
    component: _7b8a59c0,
    meta: {"auth":true},
    name: "user-change_password"
  }, {
    path: "/user/contact_information",
    component: _9ca3183a,
    meta: {"auth":true},
    name: "user-contact_information"
  }, {
    path: "/user/dashboard",
    component: _5ed150ea,
    meta: {"name":"user-view","auth":true},
    name: "user-dashboard"
  }, {
    path: "/user/shipping_address",
    component: _7f222806,
    meta: {"auth":true},
    name: "user-shipping_address"
  }, {
    path: "/checkout/old",
    component: _1e595d28,
    meta: {"name":"checkout-view"},
    name: "checkout-old"
  }, {
    path: "/designers/account_verifing",
    component: _4ef17208,
    meta: {"name":"DesignerAccountVerifing"},
    name: "designers-account_verifing"
  }, {
    path: "/designers/account_verify",
    component: _aa959ec2,
    meta: {"name":"designer-account-verify"},
    name: "designers-account_verify"
  }, {
    path: "/designers/signup",
    component: _8fc060a8,
    meta: {"name":"designer-signup"},
    name: "designers-signup"
  }, {
    path: "/financing/old",
    component: _74000856,
    meta: {"name":"financing-view"},
    name: "financing-old"
  }, {
    path: "/gift_cards/gift_card_balance",
    component: _454d29ae,
    meta: {"name":"GiftCardsBalance","transition":{"name":"slide","mode":"out-in"}},
    name: "gift_cards-gift_card_balance"
  }, {
    path: "/rooms/categories/:name",
    component: _123fe53b,
    meta: {"name":"CategoryRoomsView"},
    name: "rooms-categories-name"
  }, {
    path: "/rooms/categories/:category?/new",
    component: _2fe9f482,
    meta: {"name":"RoomView","auth":"admin"},
    name: "rooms-categories-category-new"
  }, {
    path: "/40-k-sweepstakes/:step",
    component: _ff15124a,
    meta: {"name":"40kSweepstakesStep"},
    name: "40-k-sweepstakes-step"
  }, {
    path: "/brands/:name",
    component: _5eec9df4,
    meta: {"name":"brand-view"},
    name: "brands-name"
  }, {
    path: "/delivery_date_requests/:id",
    component: _0a7079e2,
    meta: {"name":"DeliveryDateRequest"},
    name: "delivery_date_requests-id"
  }, {
    path: "/designers/:username",
    component: _1efd0ef7,
    meta: {"name":"Designer"},
    name: "designers-username"
  }, {
    path: "/my_orders/:id?",
    component: _090c67a9,
    meta: {"name":"TrackOrder"},
    name: "my_orders-id"
  }, {
    path: "/pages/:url_handler?",
    component: _ff592e20,
    meta: {"middleware":"static_page"},
    name: "pages-url_handler"
  }, {
    path: "/products/:slug",
    component: _00571d2c,
    meta: {"name":"Product"},
    name: "products-slug"
  }, {
    path: "/reset_password/:token?",
    component: _0263a7b8,
    meta: {"name":"reset-password-view","middleware":"redirect_signed_in"},
    name: "reset_password-token"
  }, {
    path: "/rooms/:slug",
    component: _2ef1889c,
    meta: {"name":"RoomView"},
    name: "rooms-slug"
  }, {
    path: "/link_order/:id?/accept",
    component: _c1619928,
    meta: {"name":"AcceptLinkOrder"},
    name: "link_order-id-accept"
  }, {
    path: "/products/:slug/old",
    component: _62a4b881,
    meta: {"name":"product-view"},
    name: "products-slug-old"
  }, {
    path: "/warehouse_quote/:id?/form",
    component: _f2c53916,
    meta: {"name":"WarehouseQuoteForm"},
    name: "warehouse_quote-id-form"
  }, {
    path: "/",
    component: _5d1cfa10,
    meta: {"name":"home-view"},
    name: "index"
  }, {
    path: "/categories/:name(.*)",
    component: _1604aa9e,
    meta: {"name":"products-view"},
    name: "categories"
  }, {
    path: "/collections/:slug",
    component: _1604aa9e,
    meta: {"name":"products-view"},
    name: "collections-slug"
  }, {
    path: "/brands/:name/:category(.*)",
    component: _5eec9df4,
    meta: {"name":"brand-view"},
    name: "brands-name-category"
  }, {
    path: "/collections/:slug/:category(.*)",
    component: _1604aa9e,
    meta: {"name":"products-view"},
    name: "collections-slug-category"
  }],

  parseQuery: function(query) {
      const qs = require('qs');
      return qs.parse(query);
    },
  stringifyQuery: function(query) {
      const qs = require('qs');
      let result = qs.stringify(query, { arrayFormat: 'brackets' });
      return result ? '?' + result : '';
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
