const BMGM_BANNER_HIDDEN_COOKIE_KEY = 'bmsvBannerHidden'

import { mapGetters } from 'vuex'
import BuyMoreGetMoreBanner from '@/components/BuyMoreGetMoreBanner'

export default {
  data () {
    return {
      buyMoreGetMoreExpiredTimer: null,
      buyMoreGetMoreExpired: false,
      buyMoreGetMoreExpiredBannerRenderKey: 1
    }
  },
  components: {
    BuyMoreGetMoreBanner
  },
  computed: {
    ...mapGetters('order', ['getUrlCampaign']),
    buyMoreGetMoreBannerVisible () {
      return false
      return (
        this.buyMoreGetMoreExpiredBannerRenderKey &&
        this.getUrlCampaign === 'bmgm-tier' &&
        !this.$cookies.get(BMGM_BANNER_HIDDEN_COOKIE_KEY) &&
        !this.buyMoreGetMoreExpired
      )
    }
  },
  methods: {
    checkBuyMoreGetMoreBannerExpired () {
      if (process.server) return

      const targetTimeString = this.$cookies.get('sanUCEA')
      if (!targetTimeString) {
        this.buyMoreGetMoreExpired = true
        return
      }

      const targetTimestamp = new Date(targetTimeString).getTime()

      this.buyMoreGetMoreExpiredTimer =
        setInterval(() => {
          if (targetTimestamp <= Date.now()) {
            this.buyMoreGetMoreExpired = true
            this.clearBuyMoreGetMoreExpiredTimer()
          }
        }, 500)
    },
    clearBuyMoreGetMoreExpiredTimer () {
      clearInterval(this.buyMoreGetMoreExpiredTimer)
    },
    hideBuyMoreGetMoreBanner () {
      ++this.buyMoreGetMoreExpiredBannerRenderKey
      this.$cookies.set(BMGM_BANNER_HIDDEN_COOKIE_KEY, true, { path: '/', maxAge: 24 * 60 * 60 })
    }
  }
}