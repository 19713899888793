<template>
<cookie-law>
  <div slot-scope="props" class="cookie-policy">
    <p class="cookie-policy__message">
        We use cookies on our website to enhance your user experience and to analyze site usage so we can further improve our website and marketing.
        By using <span class="cookie-policy__line-break">our site you agree to our use of cookies.</span>
    </p>
    <div class="cookie-policy__actions">
        <button class="cookie-policy__action btn" @click="props.accept">OK</button>
    </div>
  </div>
</cookie-law>
</template>

<script>
export default {
    components: {
        CookieLaw: () => (process.client ? import('vue-cookie-law') : null)
    }
}
</script>

<style lang="scss" scoped>
.Cookie--base {
    background: #FFFFFF;
    box-shadow: 10px 0px 10px 5px rgba(0, 0, 0, 0.149);
    bottom: 8px;
}

.cookie-policy {
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    gap: 32px;
    justify-content: center;
    width: 100%;
    padding: 0.33rem 1rem;

    &__message {
        margin: 0;
        margin-top: 2px;
        font-family: MotivaSans;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.05em;
    }

    &__actions {
        display: flex;
        gap: 12px;
    }

    &__action {
        font-size: 16px;
        line-height: 1;
        padding: 9px 40px;
    }

    &__line-break {
        display: block;
    }
}

@media only screen and (max-width: 768px) {
    .Cookie--base {
        bottom: 0px;
    }

    .cookie-policy {
        flex-direction: column;

        &__line-break {
            display: inline;
        }
    }
}
</style>