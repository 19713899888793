import { uniqBy, take, compact } from 'lodash'

const LOCAL_STORE_RECENTLY_VIEWED_PRODUCTS = 'sanRVP'

function getLatestProducts (products) {
    let result = uniqBy(products, 'item.id').sort((a, b) => b.addedAt - a.addedAt)

    return take(result, 10)
}

export const state = () => ({
    products: []
})
export const getters = {
    getProducts: state => compact(state.products.map(product => product.item))
}
export const mutations = {
    addRecentlyViewed(state, payload) {
        if (!payload) return

        const newProduct = { item: payload, addedAt: +new Date() }
        state.products = getLatestProducts([...state.products, newProduct])
        if (process.client) {
            localStorage.setItem(LOCAL_STORE_RECENTLY_VIEWED_PRODUCTS, JSON.stringify(state.products))
        }
    },
    loadRecentlyViewed(state) {
        let storedProducts = []
        if (process.client) {
            storedProducts = JSON.parse(localStorage.getItem(LOCAL_STORE_RECENTLY_VIEWED_PRODUCTS)) || []
        }
        state.products = getLatestProducts([...state.products, ...storedProducts])
    }
}
export const actions = {
    addRecentlyViewed({ commit }, payload) {
        commit('loadRecentlyViewed')
        commit('addRecentlyViewed', payload)
    },
    loadRecentlyViewed({ commit }) {
        commit('loadRecentlyViewed')
    }
}
