import * as api_config from '../config/api_config'

export default {
    getSlides(axios) {
        return axios.get(`${api_config.ADDRESS}/slides`, {headers: api_config.HEADERS});
    },
    getTopSlides(axios) {
        return axios.get(`${api_config.ADDRESS}/slides/top_slides`, {headers: api_config.HEADERS});
    },
    getActiveSlide(axios, params) {
        return axios.get(`${api_config.ADDRESS}/slides/active`, {params, headers: api_config.HEADERS})
    }
}
