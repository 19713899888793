// limited to 600px by max-width: 600px; in modal styling
const DEFAULT_WIDTH = 600

export const state = () => ({
    active: false,
    component: '',
    width: DEFAULT_WIDTH,
    maxSize: false,
    props: {},
    listeners: {},
    response: null,
    classes: [],
    notHideOnClickOutside: false
})
export const getters = {
    getState: state =>  state.active,
    getWidth: state =>  state.width,
    getMaxSize: state => state.maxSize,
    getComponent: state =>  state.component,
    getProps: state =>  state.props,
    getListeners: state =>  state.listeners,
    getResponse: state => state.response,
    getClasses: state => state.classes,
    getNotHideOnClickOutside: state => state.notHideOnClickOutside
}
export const mutations = {
    show (state, payload) {
        state.component = payload.component
        state.width = payload.width || DEFAULT_WIDTH
        state.maxSize = payload.maxSize
        state.props = payload.props
        state.listeners = payload.listeners
        state.active = true
        state.classes = payload.classes || []
        state.notHideOnClickOutside = payload.notHideOnClickOutside
    },
    hide (state) {
        state.component = ''
        state.width = DEFAULT_WIDTH
        state.maxSize = false
        state.active = false
        state.classes = []
        state.notHideOnClickOutside = false
    },
    setResponse (state, payload) {
        state.response = payload
    },
    clearResponse (state) {
        state.response = null
    }
}
export const actions = {
    showModal ({ commit }, payload) {
        commit('show', payload)
        document.body.classList.add('noscroll')
    },
    hideModal ({ commit }) {
        commit('hide')
        document.body.classList.remove('noscroll')
    },
    setResponse({ commit }, payload) {
        commit('setResponse', payload)
    },
    clearResponse({ commit }) {
        commit('clearResponse')
    }
}