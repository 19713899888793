<template>
  <carousel :slidesLoaded="topSlidesLoaded"
            :slides="top"
            :sizes="sizes"
            :hasSkeleton="false"
            :show-controls="false"/>
</template>

<script>
import ApiSlide from '@/api/Slide'
import Carousel from '@/components/Carousel'

export default {
  name: 'header-promotion',
  components: {
    Carousel
  },
  data: function () {
    return {
      top: [],
      topSlidesLoaded: false,
      sizes: {
        desktop: {
          width: '1920px',
          height: '60px'
        },
        mobile: {
          width: '400px',
          height: '40px'
        }
      }
    }
  },
  async fetch() {
    await ApiSlide.getTopSlides(this.$axios)
        .then(response => {
          this.top = response.data.top
        })
        .catch(error => {
          console.log('error ApiSlide.getTopSlides')
        })
        .finally(() => {
          this.topSlidesLoaded = true
        })
  }
}
</script>

<style lang="scss" scoped>

.banner-preview {
  max-width: 1920px;
  position: relative;
  margin: 0 auto;

  img {
    width: 100%;
    max-height: 300px;
  }

  .mobile-image {
    display: none;
  }

  .big-image {
    display: block;
  }

  @media only screen and (max-width: 768px) {
    .mobile-image {
      display: block;
    }

    .big-image {
      display: none;
    }
  }
}

.banner-preview:hover {
  cursor: pointer;
}

</style>
